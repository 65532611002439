import Cookies from 'universal-cookie';
import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root'
})
export class CookieService {
    private cookies: Cookies;


     // Inicializa la instancia de universal-cookie
    constructor() {
        this.cookies = new Cookies(); 
    }


    // Obtener una cookie
    getCookie(name: string): string | undefined {
        return this.cookies.get(name);
    }

    // Eliminar una cookie
    removeCookie(name: string): void {
        this.cookies.remove(name);
    }
}